import _ from 'lodash';
/* eslint-disable max-len */

import { createSelector } from 'reselect';

import {
  defaultSettings,
  defaultSettingsEditorX,
  CHANNEL_VIDEO_LIST_VALUES,
  COMPACT_SHOW_VIDEO_LIST_VALUES,
  PLAY_TYPE_VALUES,
  MAIN_ACTION_STYLE_TYPES,
  LAYOUT_STYLE_VALUES,
  CHANNEL_LAYOUT_VALUES,
  MOBILE_CHANNEL_LAYOUT_VALUES,
  SHOW_INFO_VALUES,
  INFO_POSITION_VALUES,
  SHOW_EXPANDED_VIDEO_LIST_INFO_VALUES,
  LOAD_MORE_BUTTON_TYPE_VALUES,
  NAVIGATION_ARROWS_BEHAVIOR,
  NAVIGATION_ARROWS_POSITION,
  THUMBNAIL_BUTTONS_BEHAVIOR,
  PREVIEW_TYPES,
  MAIN_ITEM_TYPE_VALUES,
} from '@wix/wix-vod-constants/app-settings';
import { RTL_LOCALES } from '@wix/wix-vod-constants/common';
import {
  getChannelForWidget,
  isSubscribedToChannel,
} from '@wix/wix-vod-shared/common';
import { isSingleVideo } from './video';

export const getAllSettings = (state) => state.appSettings;

export const isResponsiveEditor = createSelector(
  getAllSettings,
  (settings) => settings.booleans.responsive,
);

const createSettingGetter = (settingPath, fallbackValue) => (state) => {
  const defaults = isResponsiveEditor(state)
    ? defaultSettingsEditorX
    : defaultSettings;

  return _.get(
    state,
    settingPath,
    fallbackValue || _.get(defaults, settingPath.replace('appSettings.', '')),
  );
};

export const getLocale = createSettingGetter('appSettings.locale');
export const getAutoplay = createSettingGetter('appSettings.booleans.autoPlay');
export const getNumberOfRows = createSettingGetter(
  'appSettings.numbers.numberOfRows',
);
export const getStretchToFullWidth = createSettingGetter(
  'appSettings.booleans.stretchToFullWidth',
);
export const getVideosInRowCount = createSettingGetter(
  'appSettings.numbers.videosInRow',
);
export const getVideoInfoPosition = createSettingGetter(
  'appSettings.numbers.videoInfoPosition',
);
export const getInfoActionVisibility = createSettingGetter(
  'appSettings.numbers.showInfoAction',
);
export const getExpandedViewInfoActionVisibility = createSettingGetter(
  'appSettings.numbers.showInfoAction',
);
export const getLayoutStyle = createSettingGetter(
  'appSettings.numbers.layoutStyle',
);
export const getExpandedViewTheme = createSettingGetter(
  'appSettings.numbers.expandedTheme',
);

export const getPlayType = createSettingGetter('appSettings.numbers.playType');
export const getCompactMainActionStyleType = createSettingGetter(
  'appSettings.numbers.mainActionStyleType',
);
export const getChannelLayout = createSettingGetter(
  'appSettings.numbers.channelLayout',
);
export const getMobileChannelLayout = createSettingGetter(
  'appSettings.numbers.mobileChannelLayout',
);
export const getMainItemOverlayOpacity = createSettingGetter(
  'appSettings.numbers.mainItemOverlayOpacity',
);
export const getThumbnailOverlayOpacity = createSettingGetter(
  'appSettings.numbers.thumbnailOverlayOpacity',
);
export const getVideoListVisibility = createSettingGetter(
  'appSettings.numbers.videoListVisibility',
);
const createVideoListVisibilitySelector = (expectedMode) =>
  createSelector(getVideoListVisibility, (mode) => mode === expectedMode);
export const isVideoListAlwaysShow = createVideoListVisibilitySelector(
  CHANNEL_VIDEO_LIST_VALUES.ALWAYS_SHOW,
);
export const isVideoListExpandOnClick = createVideoListVisibilitySelector(
  CHANNEL_VIDEO_LIST_VALUES.EXPAND_ON_CLICK,
);
export const isVideoListNeverShow = createVideoListVisibilitySelector(
  CHANNEL_VIDEO_LIST_VALUES.NEVER_SHOW,
);
export const getCompactShowVideoListType = createSettingGetter(
  'appSettings.numbers.compactShowVideoListType',
);
export const getShowSearch = createSettingGetter(
  'appSettings.booleans.showSearch',
);
export const getThumbnailSpacing = createSettingGetter(
  'appSettings.numbers.thumbnailSpacing',
);
export const getSliderHeight = createSettingGetter(
  'appSettings.numbers.sliderHeight',
);
export const getTextAlignment = createSettingGetter(
  'appSettings.numbers.textAlignment',
);
export const getUseOptimalFontSizes = createSettingGetter(
  'appSettings.booleans.useOptimalFontSizes',
);
export const getTitleFont = createSettingGetter('appSettings.fonts.titleFont');
export const getTextFont = createSettingGetter('appSettings.fonts.textFont');
export const getThumbnailOverlayColor = createSettingGetter(
  'appSettings.colors.thumbnailOverlayColor',
);
export const getLoadMoreButtonType = createSettingGetter(
  'appSettings.numbers.loadMoreButtonType',
);
export const getVideoEndOption = createSettingGetter(
  'appSettings.numbers.videoEndOption',
);
export const isLoadMoreDisplayedAsLink = createSelector(
  getLoadMoreButtonType,
  (type) => type === LOAD_MORE_BUTTON_TYPE_VALUES.TEXT,
);

const createIsLayoutSelector = (layoutType) =>
  createSelector(
    getChannelLayout,
    (channelLayout) => channelLayout === layoutType,
  );

export const isRTL = createSelector(
  getLocale,
  (locale) => locale && RTL_LOCALES.indexOf(locale) !== -1,
);

export const isVerticalLayoutMobile = createSelector(
  getMobileChannelLayout,
  (mobileChannelLayout) =>
    mobileChannelLayout === MOBILE_CHANNEL_LAYOUT_VALUES.VERTICAL,
);
export const isCompactLayout = createIsLayoutSelector(
  CHANNEL_LAYOUT_VALUES.COMPACT,
);
export const isStripLayout = createIsLayoutSelector(
  CHANNEL_LAYOUT_VALUES.STRIP,
);
export const isClassicLayout = createIsLayoutSelector(
  CHANNEL_LAYOUT_VALUES.CLASSIC,
);
export const isGridLayout = createIsLayoutSelector(CHANNEL_LAYOUT_VALUES.GRID);
export const isSliderLayout = createSelector(
  getChannelLayout,
  (channelLayout) =>
    channelLayout === CHANNEL_LAYOUT_VALUES.SLIDER_BIG ||
    channelLayout === CHANNEL_LAYOUT_VALUES.SLIDER_SMALL,
);

export const canShowSearch = createSelector(
  getShowSearch,
  isCompactLayout,
  (isSearchVisible, isCompact) => isSearchVisible && !isCompact,
);

export const canShowMainItemTitle = createSettingGetter(
  'appSettings.booleans.showTitleForMainItem',
);
export const getCanShowChannelInfo = createSettingGetter(
  'appSettings.booleans.showChannelInfo',
);
export const getCanShowChannelShare = createSettingGetter(
  'appSettings.booleans.showChannelShare',
);

export const getCanShowSignInBySetting = createSettingGetter(
  'appSettings.booleans.showSignIn',
);

export const getCanShowSignIn = createSelector(
  getCanShowSignInBySetting,
  getChannelForWidget,
  (canShow, channelData) => canShow || isSubscribedToChannel(channelData),
);

export const canShowChannelShare = createSelector(
  getCanShowChannelShare,
  isSingleVideo,
  (isChannelShareVisible, isSingleVideo) => {
    if (isSingleVideo) {
      return false;
    }

    return isChannelShareVisible;
  },
);

export const canShowVideoList = createSelector(
  getCompactShowVideoListType,
  (compactShowVideoListType) =>
    compactShowVideoListType !== COMPACT_SHOW_VIDEO_LIST_VALUES.NEVER_SHOW,
);

export const canShowActionBar = createSelector(
  canShowVideoList,
  getCanShowChannelShare,
  getCanShowChannelInfo,
  getCanShowSignIn,
  isSingleVideo,
  (
    showVideoList,
    showChannelShare,
    showChannelInfo,
    showSignIn,
    isSingleVideo,
  ) =>
    !isSingleVideo &&
    (showVideoList || showChannelShare || showChannelInfo || showSignIn),
);

export const canShowVideoListStrip = createSelector(
  getVideoListVisibility,
  (videoListVisibility) =>
    videoListVisibility !== CHANNEL_VIDEO_LIST_VALUES.NEVER_SHOW,
);

export const getShowPublisherForMainItem = createSettingGetter(
  'appSettings.booleans.showPublisherForMainItem',
);

export const canShowMainItemPublisher = createSelector(
  getShowPublisherForMainItem,
  isCompactLayout,
  (isPublisherVisible, isCompact) => isPublisherVisible && !isCompact,
);

const getPreviewType = createSettingGetter('appSettings.numbers.previewType');

const createIsPreviewOfTypeSelector = (type) =>
  createSelector(getPreviewType, (previewType) => previewType === type);

export const isThumbnailsPreviewHover = createIsPreviewOfTypeSelector(
  PREVIEW_TYPES.THUMBNAILS_HOVER,
);
export const isMenuPreview = createIsPreviewOfTypeSelector(PREVIEW_TYPES.MENU);
export const isActionBarPreview = createIsPreviewOfTypeSelector(
  PREVIEW_TYPES.ACTION_BAR,
);
export const isNavigationArrowsPreview = createIsPreviewOfTypeSelector(
  PREVIEW_TYPES.NAVIGATION_ARROWS,
);

export const getNavigationArrowsBehavior = createSettingGetter(
  'appSettings.numbers.navigationArrowsBehavior',
);
export const getThumbnailButtonsBehavior = createSettingGetter(
  'appSettings.numbers.thumbnailButtonsBehavior',
);

export const isSliderNavAlwaysVisible = createSelector(
  getNavigationArrowsBehavior,
  isNavigationArrowsPreview,
  (behaviorType, isPreview) =>
    behaviorType === NAVIGATION_ARROWS_BEHAVIOR.ALWAYS || isPreview,
);

export const isButtonsShownOnHover = createSelector(
  getThumbnailButtonsBehavior,
  isThumbnailsPreviewHover,
  (behaviorType, isPreview) =>
    behaviorType === THUMBNAIL_BUTTONS_BEHAVIOR.ON_HOVER && !isPreview,
);

export const getNavigationArrowsPosition = createSettingGetter(
  'appSettings.numbers.navigationArrowsPosition',
);

export const isNavigationArrowsOutside = createSelector(
  getNavigationArrowsPosition,
  (position) => position === NAVIGATION_ARROWS_POSITION.OUTSIDE,
);

export const getVideoListPublisherVisibility = createSettingGetter(
  'appSettings.booleans.showVideoPublisherInList',
);

export const canShowVideoListItemPublisher = createSelector(
  getVideoListPublisherVisibility,
  isCompactLayout,
  (isPublisherVisible, isCompact) => isPublisherVisible && !isCompact,
);

export const isCommentsEnabled = createSettingGetter(
  'appSettings.booleans.allowComments',
);
export const isCommentsEnabledForLive = createSettingGetter(
  'appSettings.booleans.allowCommentsLive',
  true,
);

export const canShowVideoListItemTitle = createSettingGetter(
  'appSettings.booleans.showVideoTitleInList',
);
export const canShowVideoListItemDuration = createSettingGetter(
  'appSettings.booleans.showVideoDurationInList',
);
export const canShowVideoListItemDescription = createSettingGetter(
  'appSettings.booleans.showVideoDescriptionInList',
);

export const canShowVideoListItemContentBelow = createSelector(
  canShowVideoListItemTitle,
  canShowVideoListItemPublisher,
  canShowVideoListItemDescription,
  (...args) => _.some(args),
);

export const canShowFullInfo = createSettingGetter(
  'appSettings.booleans.showFullInfo',
);
export const getCanShareVideo = createSettingGetter(
  'appSettings.booleans.enableVideosSharing',
);

export const getCanShowChannelTags = createSettingGetter(
  'appSettings.booleans.showChannelTags',
);

export const canShowChannelTags = createSelector(
  canShowSearch,
  getCanShowChannelTags,
  (canShowSearch, canShowTags) => canShowSearch && canShowTags,
);

export const canShowExpandedVideoInfo = createSettingGetter(
  'appSettings.booleans.showExpandedVideoInfo',
);
export const canShowExpandedVideoShare = createSettingGetter(
  'appSettings.booleans.enableExpandedVideosSharing',
);
export const canShowExpandedSignIn = createSelector(
  createSettingGetter('appSettings.booleans.showExpandedSignIn'),
  getCanShowSignIn,
  isSliderLayout,
  isGridLayout,
  (isExpandedSignInVisible, isSignInVisible, isSlider, isGrid) =>
    isSlider || isGrid ? isExpandedSignInVisible : isSignInVisible,
);
export const canShowExpandedChannelTitle = createSettingGetter(
  'appSettings.booleans.showExpandedChannelTitle',
);
export const canShowExpandedVideoTitle = createSettingGetter(
  'appSettings.booleans.showExpandedVideoTitle',
);
export const canShowExpandedVideoList = createSettingGetter(
  'appSettings.booleans.showExpandedVideoList',
);
export const canShowChannelTitle = createSettingGetter(
  'appSettings.booleans.showChannelTitle',
);
export const canShowChannelCategories = createSettingGetter(
  'appSettings.booleans.showChannelCategories',
);
export const getMainItemType = createSettingGetter(
  'appSettings.numbers.mainItemType',
);
export const getContainerMargins = createSettingGetter(
  'appSettings.numbers.containerMargins',
);

export const isMainItemChannelCover = createSelector(
  getMainItemType,
  (mainItemType) => mainItemType === MAIN_ITEM_TYPE_VALUES.CHANNEL_COVER,
);

export const areElementsRounded = createSelector(
  getLayoutStyle,
  (layoutStyle) => layoutStyle === LAYOUT_STYLE_VALUES.ROUND,
);

export const isPlayInFrame = createSelector(
  getPlayType,
  (playType) => playType === PLAY_TYPE_VALUES.IN_FRAME,
);

export const isCompactMainActionsOnlyIcons = createSelector(
  getCompactMainActionStyleType,
  (styleType) => styleType === MAIN_ACTION_STYLE_TYPES.ICON,
);

export const isButtonsTextShown = createSelector(
  getCompactMainActionStyleType,
  (styleType) => styleType === MAIN_ACTION_STYLE_TYPES.ICON_WITH_TEXT,
);

export const isVideosListInfoAlwaysShown = createSelector(
  getInfoActionVisibility,
  (visibilityType) => visibilityType === SHOW_INFO_VALUES.ALWAYS,
);

export const isVideosListInfoNeverShown = createSelector(
  getInfoActionVisibility,
  (visibilityType) => visibilityType === SHOW_INFO_VALUES.NEVER,
);

export const isVideosListInfoPositionBelow = createSelector(
  getVideoInfoPosition,
  (videoInfoPosition) =>
    videoInfoPosition === INFO_POSITION_VALUES.BELOW_VIDEOS,
);

export const isVideosListInfoShownOnHover = createSelector(
  getInfoActionVisibility,
  (visibilityType) => visibilityType === SHOW_INFO_VALUES.ON_MOUSE_OVER,
);

export const isExpandedModeVideosListInfoAlwaysShown = createSelector(
  getExpandedViewInfoActionVisibility,
  (visibilityType) =>
    visibilityType === SHOW_EXPANDED_VIDEO_LIST_INFO_VALUES.ALWAYS,
);

export const isExpandedModeVideosListInfoShownOnHover = createSelector(
  getExpandedViewInfoActionVisibility,
  (visibilityType) =>
    visibilityType === SHOW_EXPANDED_VIDEO_LIST_INFO_VALUES.ON_MOUSE_OVER,
);
